export default {
    "address": "0x2064b9C6D3baDa53FE305eA5fDdF26056b3aBf4e",
    "abi": [
        {
            "type": "constructor",
            "inputs": [
                {
                    "name": "initialOwner",
                    "type": "address",
                    "internalType": "address"
                },
                {
                    "name": "_checker",
                    "type": "address",
                    "internalType": "contract IMarginChecker"
                }
            ],
            "stateMutability": "nonpayable"
        },
        {
            "type": "receive",
            "stateMutability": "payable"
        },
        {
            "type": "function",
            "name": "ONE_BILLION",
            "inputs": [],
            "outputs": [
                {
                    "name": "",
                    "type": "uint256",
                    "internalType": "uint256"
                }
            ],
            "stateMutability": "view"
        },
        {
            "type": "function",
            "name": "ONE_MILLION",
            "inputs": [],
            "outputs": [
                {
                    "name": "",
                    "type": "uint256",
                    "internalType": "uint256"
                }
            ],
            "stateMutability": "view"
        },
        {
            "type": "function",
            "name": "YEAR_SECONDS",
            "inputs": [],
            "outputs": [
                {
                    "name": "",
                    "type": "uint256",
                    "internalType": "uint256"
                }
            ],
            "stateMutability": "view"
        },
        {
            "type": "function",
            "name": "approve",
            "inputs": [
                {
                    "name": "to",
                    "type": "address",
                    "internalType": "address"
                },
                {
                    "name": "tokenId",
                    "type": "uint256",
                    "internalType": "uint256"
                }
            ],
            "outputs": [],
            "stateMutability": "nonpayable"
        },
        {
            "type": "function",
            "name": "balanceOf",
            "inputs": [
                {
                    "name": "owner",
                    "type": "address",
                    "internalType": "address"
                }
            ],
            "outputs": [
                {
                    "name": "",
                    "type": "uint256",
                    "internalType": "uint256"
                }
            ],
            "stateMutability": "view"
        },
        {
            "type": "function",
            "name": "checker",
            "inputs": [],
            "outputs": [
                {
                    "name": "",
                    "type": "address",
                    "internalType": "contract IMarginChecker"
                }
            ],
            "stateMutability": "view"
        },
        {
            "type": "function",
            "name": "close",
            "inputs": [
                {
                    "name": "positionId",
                    "type": "uint256",
                    "internalType": "uint256"
                },
                {
                    "name": "repayMillionth",
                    "type": "uint256",
                    "internalType": "uint256"
                },
                {
                    "name": "pnlMinAmount",
                    "type": "int256",
                    "internalType": "int256"
                },
                {
                    "name": "deadline",
                    "type": "uint256",
                    "internalType": "uint256"
                }
            ],
            "outputs": [],
            "stateMutability": "payable"
        },
        {
            "type": "function",
            "name": "estimatePNL",
            "inputs": [
                {
                    "name": "positionId",
                    "type": "uint256",
                    "internalType": "uint256"
                },
                {
                    "name": "repayMillionth",
                    "type": "uint256",
                    "internalType": "uint256"
                }
            ],
            "outputs": [
                {
                    "name": "pnlAmount",
                    "type": "int256",
                    "internalType": "int256"
                }
            ],
            "stateMutability": "view"
        },
        {
            "type": "function",
            "name": "getApproved",
            "inputs": [
                {
                    "name": "tokenId",
                    "type": "uint256",
                    "internalType": "uint256"
                }
            ],
            "outputs": [
                {
                    "name": "",
                    "type": "address",
                    "internalType": "address"
                }
            ],
            "stateMutability": "view"
        },
        {
            "type": "function",
            "name": "getHook",
            "inputs": [],
            "outputs": [
                {
                    "name": "_hook",
                    "type": "address",
                    "internalType": "address"
                }
            ],
            "stateMutability": "view"
        },
        {
            "type": "function",
            "name": "getMarginMax",
            "inputs": [
                {
                    "name": "poolId",
                    "type": "bytes32",
                    "internalType": "PoolId"
                },
                {
                    "name": "marginForOne",
                    "type": "bool",
                    "internalType": "bool"
                },
                {
                    "name": "leverage",
                    "type": "uint24",
                    "internalType": "uint24"
                }
            ],
            "outputs": [
                {
                    "name": "marginMax",
                    "type": "uint256",
                    "internalType": "uint256"
                },
                {
                    "name": "borrowAmount",
                    "type": "uint256",
                    "internalType": "uint256"
                }
            ],
            "stateMutability": "view"
        },
        {
            "type": "function",
            "name": "getMarginTotal",
            "inputs": [
                {
                    "name": "poolId",
                    "type": "bytes32",
                    "internalType": "PoolId"
                },
                {
                    "name": "marginForOne",
                    "type": "bool",
                    "internalType": "bool"
                },
                {
                    "name": "leverage",
                    "type": "uint24",
                    "internalType": "uint24"
                },
                {
                    "name": "marginAmount",
                    "type": "uint256",
                    "internalType": "uint256"
                }
            ],
            "outputs": [
                {
                    "name": "marginWithoutFee",
                    "type": "uint256",
                    "internalType": "uint256"
                },
                {
                    "name": "borrowAmount",
                    "type": "uint256",
                    "internalType": "uint256"
                }
            ],
            "stateMutability": "view"
        },
        {
            "type": "function",
            "name": "getMaxDecrease",
            "inputs": [
                {
                    "name": "positionId",
                    "type": "uint256",
                    "internalType": "uint256"
                }
            ],
            "outputs": [
                {
                    "name": "maxAmount",
                    "type": "uint256",
                    "internalType": "uint256"
                }
            ],
            "stateMutability": "view"
        },
        {
            "type": "function",
            "name": "getPosition",
            "inputs": [
                {
                    "name": "positionId",
                    "type": "uint256",
                    "internalType": "uint256"
                }
            ],
            "outputs": [
                {
                    "name": "_position",
                    "type": "tuple",
                    "internalType": "struct MarginPosition",
                    "components": [
                        {
                            "name": "poolId",
                            "type": "bytes32",
                            "internalType": "PoolId"
                        },
                        {
                            "name": "marginForOne",
                            "type": "bool",
                            "internalType": "bool"
                        },
                        {
                            "name": "marginAmount",
                            "type": "uint128",
                            "internalType": "uint128"
                        },
                        {
                            "name": "marginTotal",
                            "type": "uint128",
                            "internalType": "uint128"
                        },
                        {
                            "name": "borrowAmount",
                            "type": "uint128",
                            "internalType": "uint128"
                        },
                        {
                            "name": "rawBorrowAmount",
                            "type": "uint128",
                            "internalType": "uint128"
                        },
                        {
                            "name": "rateCumulativeLast",
                            "type": "uint256",
                            "internalType": "uint256"
                        }
                    ]
                }
            ],
            "stateMutability": "view"
        },
        {
            "type": "function",
            "name": "getPositionId",
            "inputs": [
                {
                    "name": "poolId",
                    "type": "bytes32",
                    "internalType": "PoolId"
                },
                {
                    "name": "marginForOne",
                    "type": "bool",
                    "internalType": "bool"
                },
                {
                    "name": "owner",
                    "type": "address",
                    "internalType": "address"
                }
            ],
            "outputs": [
                {
                    "name": "_positionId",
                    "type": "uint256",
                    "internalType": "uint256"
                }
            ],
            "stateMutability": "view"
        },
        {
            "type": "function",
            "name": "getPositions",
            "inputs": [
                {
                    "name": "positionIds",
                    "type": "uint256[]",
                    "internalType": "uint256[]"
                }
            ],
            "outputs": [
                {
                    "name": "_position",
                    "type": "tuple[]",
                    "internalType": "struct MarginPositionVo[]",
                    "components": [
                        {
                            "name": "position",
                            "type": "tuple",
                            "internalType": "struct MarginPosition",
                            "components": [
                                {
                                    "name": "poolId",
                                    "type": "bytes32",
                                    "internalType": "PoolId"
                                },
                                {
                                    "name": "marginForOne",
                                    "type": "bool",
                                    "internalType": "bool"
                                },
                                {
                                    "name": "marginAmount",
                                    "type": "uint128",
                                    "internalType": "uint128"
                                },
                                {
                                    "name": "marginTotal",
                                    "type": "uint128",
                                    "internalType": "uint128"
                                },
                                {
                                    "name": "borrowAmount",
                                    "type": "uint128",
                                    "internalType": "uint128"
                                },
                                {
                                    "name": "rawBorrowAmount",
                                    "type": "uint128",
                                    "internalType": "uint128"
                                },
                                {
                                    "name": "rateCumulativeLast",
                                    "type": "uint256",
                                    "internalType": "uint256"
                                }
                            ]
                        },
                        {
                            "name": "pnl",
                            "type": "int256",
                            "internalType": "int256"
                        }
                    ]
                }
            ],
            "stateMutability": "view"
        },
        {
            "type": "function",
            "name": "isApprovedForAll",
            "inputs": [
                {
                    "name": "owner",
                    "type": "address",
                    "internalType": "address"
                },
                {
                    "name": "operator",
                    "type": "address",
                    "internalType": "address"
                }
            ],
            "outputs": [
                {
                    "name": "",
                    "type": "bool",
                    "internalType": "bool"
                }
            ],
            "stateMutability": "view"
        },
        {
            "type": "function",
            "name": "liquidateBurn",
            "inputs": [
                {
                    "name": "params",
                    "type": "tuple",
                    "internalType": "struct BurnParams",
                    "components": [
                        {
                            "name": "poolId",
                            "type": "bytes32",
                            "internalType": "PoolId"
                        },
                        {
                            "name": "marginForOne",
                            "type": "bool",
                            "internalType": "bool"
                        },
                        {
                            "name": "positionIds",
                            "type": "uint256[]",
                            "internalType": "uint256[]"
                        },
                        {
                            "name": "signature",
                            "type": "bytes",
                            "internalType": "bytes"
                        }
                    ]
                }
            ],
            "outputs": [
                {
                    "name": "profit",
                    "type": "uint256",
                    "internalType": "uint256"
                }
            ],
            "stateMutability": "nonpayable"
        },
        {
            "type": "function",
            "name": "liquidateBurn",
            "inputs": [
                {
                    "name": "positionId",
                    "type": "uint256",
                    "internalType": "uint256"
                },
                {
                    "name": "signature",
                    "type": "bytes",
                    "internalType": "bytes"
                }
            ],
            "outputs": [
                {
                    "name": "profit",
                    "type": "uint256",
                    "internalType": "uint256"
                }
            ],
            "stateMutability": "nonpayable"
        },
        {
            "type": "function",
            "name": "liquidateCall",
            "inputs": [
                {
                    "name": "positionId",
                    "type": "uint256",
                    "internalType": "uint256"
                },
                {
                    "name": "signature",
                    "type": "bytes",
                    "internalType": "bytes"
                }
            ],
            "outputs": [
                {
                    "name": "profit",
                    "type": "uint256",
                    "internalType": "uint256"
                }
            ],
            "stateMutability": "payable"
        },
        {
            "type": "function",
            "name": "margin",
            "inputs": [
                {
                    "name": "params",
                    "type": "tuple",
                    "internalType": "struct MarginParams",
                    "components": [
                        {
                            "name": "poolId",
                            "type": "bytes32",
                            "internalType": "PoolId"
                        },
                        {
                            "name": "marginForOne",
                            "type": "bool",
                            "internalType": "bool"
                        },
                        {
                            "name": "leverage",
                            "type": "uint24",
                            "internalType": "uint24"
                        },
                        {
                            "name": "marginAmount",
                            "type": "uint256",
                            "internalType": "uint256"
                        },
                        {
                            "name": "marginTotal",
                            "type": "uint256",
                            "internalType": "uint256"
                        },
                        {
                            "name": "borrowAmount",
                            "type": "uint256",
                            "internalType": "uint256"
                        },
                        {
                            "name": "borrowMinAmount",
                            "type": "uint256",
                            "internalType": "uint256"
                        },
                        {
                            "name": "recipient",
                            "type": "address",
                            "internalType": "address"
                        },
                        {
                            "name": "deadline",
                            "type": "uint256",
                            "internalType": "uint256"
                        }
                    ]
                }
            ],
            "outputs": [
                {
                    "name": "",
                    "type": "uint256",
                    "internalType": "uint256"
                },
                {
                    "name": "",
                    "type": "uint256",
                    "internalType": "uint256"
                }
            ],
            "stateMutability": "payable"
        },
        {
            "type": "function",
            "name": "minMarginLevel",
            "inputs": [],
            "outputs": [
                {
                    "name": "",
                    "type": "uint24",
                    "internalType": "uint24"
                }
            ],
            "stateMutability": "view"
        },
        {
            "type": "function",
            "name": "modify",
            "inputs": [
                {
                    "name": "positionId",
                    "type": "uint256",
                    "internalType": "uint256"
                },
                {
                    "name": "changeAmount",
                    "type": "int256",
                    "internalType": "int256"
                }
            ],
            "outputs": [],
            "stateMutability": "payable"
        },
        {
            "type": "function",
            "name": "name",
            "inputs": [],
            "outputs": [
                {
                    "name": "",
                    "type": "string",
                    "internalType": "string"
                }
            ],
            "stateMutability": "view"
        },
        {
            "type": "function",
            "name": "owner",
            "inputs": [],
            "outputs": [
                {
                    "name": "",
                    "type": "address",
                    "internalType": "address"
                }
            ],
            "stateMutability": "view"
        },
        {
            "type": "function",
            "name": "ownerOf",
            "inputs": [
                {
                    "name": "tokenId",
                    "type": "uint256",
                    "internalType": "uint256"
                }
            ],
            "outputs": [
                {
                    "name": "",
                    "type": "address",
                    "internalType": "address"
                }
            ],
            "stateMutability": "view"
        },
        {
            "type": "function",
            "name": "repay",
            "inputs": [
                {
                    "name": "positionId",
                    "type": "uint256",
                    "internalType": "uint256"
                },
                {
                    "name": "repayAmount",
                    "type": "uint256",
                    "internalType": "uint256"
                },
                {
                    "name": "deadline",
                    "type": "uint256",
                    "internalType": "uint256"
                }
            ],
            "outputs": [],
            "stateMutability": "payable"
        },
        {
            "type": "function",
            "name": "safeTransferFrom",
            "inputs": [
                {
                    "name": "from",
                    "type": "address",
                    "internalType": "address"
                },
                {
                    "name": "to",
                    "type": "address",
                    "internalType": "address"
                },
                {
                    "name": "tokenId",
                    "type": "uint256",
                    "internalType": "uint256"
                }
            ],
            "outputs": [],
            "stateMutability": "nonpayable"
        },
        {
            "type": "function",
            "name": "safeTransferFrom",
            "inputs": [
                {
                    "name": "from",
                    "type": "address",
                    "internalType": "address"
                },
                {
                    "name": "to",
                    "type": "address",
                    "internalType": "address"
                },
                {
                    "name": "tokenId",
                    "type": "uint256",
                    "internalType": "uint256"
                },
                {
                    "name": "data",
                    "type": "bytes",
                    "internalType": "bytes"
                }
            ],
            "outputs": [],
            "stateMutability": "nonpayable"
        },
        {
            "type": "function",
            "name": "setApprovalForAll",
            "inputs": [
                {
                    "name": "operator",
                    "type": "address",
                    "internalType": "address"
                },
                {
                    "name": "approved",
                    "type": "bool",
                    "internalType": "bool"
                }
            ],
            "outputs": [],
            "stateMutability": "nonpayable"
        },
        {
            "type": "function",
            "name": "setHook",
            "inputs": [
                {
                    "name": "_hook",
                    "type": "address",
                    "internalType": "address"
                }
            ],
            "outputs": [],
            "stateMutability": "nonpayable"
        },
        {
            "type": "function",
            "name": "setMarginChecker",
            "inputs": [
                {
                    "name": "_checker",
                    "type": "address",
                    "internalType": "address"
                }
            ],
            "outputs": [],
            "stateMutability": "nonpayable"
        },
        {
            "type": "function",
            "name": "setMinMarginLevel",
            "inputs": [
                {
                    "name": "_minMarginLevel",
                    "type": "uint24",
                    "internalType": "uint24"
                }
            ],
            "outputs": [],
            "stateMutability": "nonpayable"
        },
        {
            "type": "function",
            "name": "supportsInterface",
            "inputs": [
                {
                    "name": "interfaceId",
                    "type": "bytes4",
                    "internalType": "bytes4"
                }
            ],
            "outputs": [
                {
                    "name": "",
                    "type": "bool",
                    "internalType": "bool"
                }
            ],
            "stateMutability": "view"
        },
        {
            "type": "function",
            "name": "symbol",
            "inputs": [],
            "outputs": [
                {
                    "name": "",
                    "type": "string",
                    "internalType": "string"
                }
            ],
            "stateMutability": "view"
        },
        {
            "type": "function",
            "name": "tokenURI",
            "inputs": [
                {
                    "name": "tokenId",
                    "type": "uint256",
                    "internalType": "uint256"
                }
            ],
            "outputs": [
                {
                    "name": "",
                    "type": "string",
                    "internalType": "string"
                }
            ],
            "stateMutability": "view"
        },
        {
            "type": "function",
            "name": "transferFrom",
            "inputs": [
                {
                    "name": "from",
                    "type": "address",
                    "internalType": "address"
                },
                {
                    "name": "to",
                    "type": "address",
                    "internalType": "address"
                },
                {
                    "name": "tokenId",
                    "type": "uint256",
                    "internalType": "uint256"
                }
            ],
            "outputs": [],
            "stateMutability": "nonpayable"
        },
        {
            "type": "function",
            "name": "transferOwnership",
            "inputs": [
                {
                    "name": "newOwner",
                    "type": "address",
                    "internalType": "address"
                }
            ],
            "outputs": [],
            "stateMutability": "nonpayable"
        },
        {
            "type": "event",
            "name": "Approval",
            "inputs": [
                {
                    "name": "owner",
                    "type": "address",
                    "indexed": true,
                    "internalType": "address"
                },
                {
                    "name": "approved",
                    "type": "address",
                    "indexed": true,
                    "internalType": "address"
                },
                {
                    "name": "tokenId",
                    "type": "uint256",
                    "indexed": true,
                    "internalType": "uint256"
                }
            ],
            "anonymous": false
        },
        {
            "type": "event",
            "name": "ApprovalForAll",
            "inputs": [
                {
                    "name": "owner",
                    "type": "address",
                    "indexed": true,
                    "internalType": "address"
                },
                {
                    "name": "operator",
                    "type": "address",
                    "indexed": true,
                    "internalType": "address"
                },
                {
                    "name": "approved",
                    "type": "bool",
                    "indexed": false,
                    "internalType": "bool"
                }
            ],
            "anonymous": false
        },
        {
            "type": "event",
            "name": "Burn",
            "inputs": [
                {
                    "name": "poolId",
                    "type": "bytes32",
                    "indexed": true,
                    "internalType": "PoolId"
                },
                {
                    "name": "sender",
                    "type": "address",
                    "indexed": true,
                    "internalType": "address"
                },
                {
                    "name": "positionId",
                    "type": "uint256",
                    "indexed": false,
                    "internalType": "uint256"
                },
                {
                    "name": "burnType",
                    "type": "uint8",
                    "indexed": false,
                    "internalType": "uint8"
                }
            ],
            "anonymous": false
        },
        {
            "type": "event",
            "name": "Close",
            "inputs": [
                {
                    "name": "poolId",
                    "type": "bytes32",
                    "indexed": true,
                    "internalType": "PoolId"
                },
                {
                    "name": "sender",
                    "type": "address",
                    "indexed": true,
                    "internalType": "address"
                },
                {
                    "name": "positionId",
                    "type": "uint256",
                    "indexed": false,
                    "internalType": "uint256"
                },
                {
                    "name": "releaseMarginAmount",
                    "type": "uint256",
                    "indexed": false,
                    "internalType": "uint256"
                },
                {
                    "name": "releaseMarginTotal",
                    "type": "uint256",
                    "indexed": false,
                    "internalType": "uint256"
                },
                {
                    "name": "repayRawAmount",
                    "type": "uint256",
                    "indexed": false,
                    "internalType": "uint256"
                },
                {
                    "name": "pnlAmount",
                    "type": "int256",
                    "indexed": false,
                    "internalType": "int256"
                }
            ],
            "anonymous": false
        },
        {
            "type": "event",
            "name": "Liquidate",
            "inputs": [
                {
                    "name": "poolId",
                    "type": "bytes32",
                    "indexed": true,
                    "internalType": "PoolId"
                },
                {
                    "name": "sender",
                    "type": "address",
                    "indexed": true,
                    "internalType": "address"
                },
                {
                    "name": "positionId",
                    "type": "uint256",
                    "indexed": false,
                    "internalType": "uint256"
                },
                {
                    "name": "marginAmount",
                    "type": "uint256",
                    "indexed": false,
                    "internalType": "uint256"
                },
                {
                    "name": "marginTotal",
                    "type": "uint256",
                    "indexed": false,
                    "internalType": "uint256"
                },
                {
                    "name": "borrowAmount",
                    "type": "uint256",
                    "indexed": false,
                    "internalType": "uint256"
                }
            ],
            "anonymous": false
        },
        {
            "type": "event",
            "name": "Margin",
            "inputs": [
                {
                    "name": "poolId",
                    "type": "bytes32",
                    "indexed": true,
                    "internalType": "PoolId"
                },
                {
                    "name": "owner",
                    "type": "address",
                    "indexed": true,
                    "internalType": "address"
                },
                {
                    "name": "positionId",
                    "type": "uint256",
                    "indexed": false,
                    "internalType": "uint256"
                },
                {
                    "name": "marginAmount",
                    "type": "uint256",
                    "indexed": false,
                    "internalType": "uint256"
                },
                {
                    "name": "marginTotal",
                    "type": "uint256",
                    "indexed": false,
                    "internalType": "uint256"
                },
                {
                    "name": "borrowAmount",
                    "type": "uint256",
                    "indexed": false,
                    "internalType": "uint256"
                },
                {
                    "name": "marginForOne",
                    "type": "bool",
                    "indexed": false,
                    "internalType": "bool"
                }
            ],
            "anonymous": false
        },
        {
            "type": "event",
            "name": "Mint",
            "inputs": [
                {
                    "name": "poolId",
                    "type": "bytes32",
                    "indexed": true,
                    "internalType": "PoolId"
                },
                {
                    "name": "sender",
                    "type": "address",
                    "indexed": true,
                    "internalType": "address"
                },
                {
                    "name": "to",
                    "type": "address",
                    "indexed": true,
                    "internalType": "address"
                },
                {
                    "name": "positionId",
                    "type": "uint256",
                    "indexed": false,
                    "internalType": "uint256"
                }
            ],
            "anonymous": false
        },
        {
            "type": "event",
            "name": "Modify",
            "inputs": [
                {
                    "name": "poolId",
                    "type": "bytes32",
                    "indexed": true,
                    "internalType": "PoolId"
                },
                {
                    "name": "sender",
                    "type": "address",
                    "indexed": true,
                    "internalType": "address"
                },
                {
                    "name": "positionId",
                    "type": "uint256",
                    "indexed": false,
                    "internalType": "uint256"
                },
                {
                    "name": "marginAmount",
                    "type": "uint256",
                    "indexed": false,
                    "internalType": "uint256"
                },
                {
                    "name": "marginTotal",
                    "type": "uint256",
                    "indexed": false,
                    "internalType": "uint256"
                },
                {
                    "name": "borrowAmount",
                    "type": "uint256",
                    "indexed": false,
                    "internalType": "uint256"
                },
                {
                    "name": "changeAmount",
                    "type": "int256",
                    "indexed": false,
                    "internalType": "int256"
                }
            ],
            "anonymous": false
        },
        {
            "type": "event",
            "name": "OwnershipTransferred",
            "inputs": [
                {
                    "name": "user",
                    "type": "address",
                    "indexed": true,
                    "internalType": "address"
                },
                {
                    "name": "newOwner",
                    "type": "address",
                    "indexed": true,
                    "internalType": "address"
                }
            ],
            "anonymous": false
        },
        {
            "type": "event",
            "name": "Repay",
            "inputs": [
                {
                    "name": "poolId",
                    "type": "bytes32",
                    "indexed": true,
                    "internalType": "PoolId"
                },
                {
                    "name": "sender",
                    "type": "address",
                    "indexed": true,
                    "internalType": "address"
                },
                {
                    "name": "positionId",
                    "type": "uint256",
                    "indexed": false,
                    "internalType": "uint256"
                },
                {
                    "name": "releaseMarginAmount",
                    "type": "uint256",
                    "indexed": false,
                    "internalType": "uint256"
                },
                {
                    "name": "releaseMarginTotal",
                    "type": "uint256",
                    "indexed": false,
                    "internalType": "uint256"
                },
                {
                    "name": "repayRawAmount",
                    "type": "uint256",
                    "indexed": false,
                    "internalType": "uint256"
                },
                {
                    "name": "pnlAmount",
                    "type": "int256",
                    "indexed": false,
                    "internalType": "int256"
                }
            ],
            "anonymous": false
        },
        {
            "type": "event",
            "name": "Transfer",
            "inputs": [
                {
                    "name": "from",
                    "type": "address",
                    "indexed": true,
                    "internalType": "address"
                },
                {
                    "name": "to",
                    "type": "address",
                    "indexed": true,
                    "internalType": "address"
                },
                {
                    "name": "tokenId",
                    "type": "uint256",
                    "indexed": true,
                    "internalType": "uint256"
                }
            ],
            "anonymous": false
        },
        {
            "type": "error",
            "name": "ERC721IncorrectOwner",
            "inputs": [
                {
                    "name": "sender",
                    "type": "address",
                    "internalType": "address"
                },
                {
                    "name": "tokenId",
                    "type": "uint256",
                    "internalType": "uint256"
                },
                {
                    "name": "owner",
                    "type": "address",
                    "internalType": "address"
                }
            ]
        },
        {
            "type": "error",
            "name": "ERC721InsufficientApproval",
            "inputs": [
                {
                    "name": "operator",
                    "type": "address",
                    "internalType": "address"
                },
                {
                    "name": "tokenId",
                    "type": "uint256",
                    "internalType": "uint256"
                }
            ]
        },
        {
            "type": "error",
            "name": "ERC721InvalidApprover",
            "inputs": [
                {
                    "name": "approver",
                    "type": "address",
                    "internalType": "address"
                }
            ]
        },
        {
            "type": "error",
            "name": "ERC721InvalidOperator",
            "inputs": [
                {
                    "name": "operator",
                    "type": "address",
                    "internalType": "address"
                }
            ]
        },
        {
            "type": "error",
            "name": "ERC721InvalidOwner",
            "inputs": [
                {
                    "name": "owner",
                    "type": "address",
                    "internalType": "address"
                }
            ]
        },
        {
            "type": "error",
            "name": "ERC721InvalidReceiver",
            "inputs": [
                {
                    "name": "receiver",
                    "type": "address",
                    "internalType": "address"
                }
            ]
        },
        {
            "type": "error",
            "name": "ERC721InvalidSender",
            "inputs": [
                {
                    "name": "sender",
                    "type": "address",
                    "internalType": "address"
                }
            ]
        },
        {
            "type": "error",
            "name": "ERC721NonexistentToken",
            "inputs": [
                {
                    "name": "tokenId",
                    "type": "uint256",
                    "internalType": "uint256"
                }
            ]
        },
        {
            "type": "error",
            "name": "InsufficientAmount",
            "inputs": [
                {
                    "name": "amount",
                    "type": "uint256",
                    "internalType": "uint256"
                }
            ]
        },
        {
            "type": "error",
            "name": "InsufficientBorrowReceived",
            "inputs": []
        },
        {
            "type": "error",
            "name": "MarginTransferFailed",
            "inputs": [
                {
                    "name": "amount",
                    "type": "uint256",
                    "internalType": "uint256"
                }
            ]
        },
        {
            "type": "error",
            "name": "PairNotExists",
            "inputs": []
        },
        {
            "type": "error",
            "name": "PositionLiquidated",
            "inputs": []
        }
    ]
}